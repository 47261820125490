<template>
    <a-modal :title="handle === 'add' ? '新增' : handle === 'edit' ? '编辑' : '查看'" style="top: 8px;" width="80%" v-model="visible" :footer="null" :maskClosable="false">
        <a-row :gutter="8">
            <a-col :span="6">
            <a-tree
                checkable
                :tree-data="treeData"
                :replace-fields="replaceFields"
                :auto-expand-parent="autoExpandParent"
                @check="onCheck"
                v-model="checkedKeys"
                :maskClosable="false"
                :disabled="handle == 'check'"
            />
            </a-col>
            <a-col :span="18">
            <a-form-model ref="form" :model="rowData" :rules="formRule" layout="horizontal" :label-col="{span:3}" :wrapper-col="{span:21}">
                <a-form-model-item label="属性名称" prop="name">
                    <a-input :disabled="handle == 'check'" v-model="rowData.name" placeholder="请输入属性名称"></a-input>
                </a-form-model-item>
                <a-form-model-item label="属性类型" prop="type">
                    <a-select placeholder="请选择" v-model="rowData.type" style="width: 30%">
                        <a-select-option :value="item.value" v-for="(item, index) in options" :key="index"> {{item.label}} </a-select-option>
                    </a-select>
                </a-form-model-item>

                <a-form-model-item v-if="rowData.type != 0" label="属性参数" prop="propertyValue">
                    <a-input :disabled="handle == 'check'" v-model="rowData.propertyValue" placeholder="请输入规格参数,用中文 “，” 隔开"></a-input>
                </a-form-model-item>

                <a-form-model-item label="排序" prop="">
                    <a-input :disabled="handle == 'check'" v-model="rowData.sort" placeholder="请输入排序"></a-input>
                </a-form-model-item>
                <a-form-model-item label="状态" prop="flagEnable">
                    <a-switch :disabled="handle == 'check'" v-model="rowData.flagEnable" checked-children="启动" un-checked-children="禁用" />
                </a-form-model-item>
            </a-form-model>
            </a-col>
        </a-row>
      <div class="footer-bts" v-if="handle != 'check'">
        <a-button key="back" @click="handleCancel">取消</a-button>
        <a-button key="submit" type="primary" :loading="loading" @click="toSubmit">提交</a-button>
      </div>
    </a-modal>
</template>

<script>
let busSiteNameText = (rule, value, callback) => {
  if (/[\u4E00-\u9FA5]/g.test(value)) {
    callback(new Error('排序不能使用文字'))
  } else {
    callback()
  }
}
import { editProductProperty, selectByIdProductProperty, addProductProperty } from '../api/ProductPropertyApi'
export default {
    data() {
        return {
            visible: false,
            handle: 'add',
            rowData: {},
            loading: false,
            treeData: [],
            autoExpandParent: true,
            checkedKeys: [],
            selectedKeys: [],
            type: 1,
            options: [
                {
                    label: '文本框',
                    value: 0
                },
                {
                    label: '单选框',
                    value: 1
                },

            ],
            replaceFields: {
                children: 'children',
                title: 'name',
                key: 'id'
            },
            // 表单验证
            formRule: {

                name: [
                    { required: true, message: '请输入属性名称', trigger: 'blur' }
                ],
                propertyValue: [
                    { required: true, message: '请输入具体的属性参数，用 “，” 隔开', trigger: 'blur' }
                ],
                type: [
                    { required: true, message: '请选择属性类型', trigger: 'change' }
                ],
                sort: [
                    { required: true, validator: busSiteNameText, trigger: 'blur' }
                ]
            }
        }
    },
    methods: {// 禁用弹框
      handleCancel() {
        this.rowData = {}
        // this.typeSpecTableData = []
        // this.typeSpecList = []
        // this.categoryIds = []
        // this.brandId = []
        this.visible = false
      },

        /**
         * 获取行数据
         */
        setRowData(row, handle){
            console.log(handle)
            this.handle = handle
            this.visible = true
            this.rowData = row
            this.checkedKeys = this.rowData.ids
            this.$set(this.rowData, 'type', 1)
            this.axios.get('/api/product/category/list').then(res => {
                this.treeData = res.body[0].children
            })
            if(handle == 'add') {
                this.$set(this.rowData, 'flagEnable', true)
            }
        },
        /**
         * 表单提交
         */
        toSubmit() {
            this.$refs.form.validate(async valid => {
                if (!valid) {
                    return
                }
                this.rowData.ids = this.checkedKeys
                const res = this.handle === 'add' ? await addProductProperty(this.rowData) : await editProductProperty(this.rowData)
                if (res.code === 200) {
                    this.$notification.success({ message: res.message })
                    this.$emit('reload')
                    this.visible = false
                    this.rowData = {}
                    this.rowData.ids = []
                } else {
                    this.$notification.error({ message: res.message })
                }
            })
        },
        onCheck(checkedKeys, info) {
            this.checkedKeys = checkedKeys
        },
        handleChange(value) {
            console.log(`selected ${value}`);
            this.type = value
        },
    },
    created() {
        this.axios.get('/api/product/category/list').then(res => {
            this.treeData = res.body[0].children
        })
    },

}
</script>

<style lang="less" scoped>
.footer-bts {
  text-align: right;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #E9E9E9;
}
</style>
